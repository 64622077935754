<template>
    <div id="content" class="user-roles">
        <template v-if="form">
            <Form :id="roleID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-buttons-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addRole" bLabel="roles.add"></Button>
            </div>
            <div class="supervise-container roles-supervise-container" :class="{ 'with-trial': trialEndDate }">
                <div class="sidebar-filters" :class="{ 'empty-results': noChanges && items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                    <FilterRoles :isDisabled="noChanges && items.length == 0" @resetPageDatatable="top(true)" @noChanges="noChanges = true" @changes="noChanges = false"></FilterRoles>
                </div>
                <div class="data-table" :class="{ 'empty-results': noChanges && items.length == 0 }">
                    <v-data-table v-if="items" v-model="selected" :headers="headers" fixed-header :items="items" sort-by="level" :sort-desc="true" hide-default-footer :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pageCount = $event">
                        <template #item.name="item">
                            <div class="created-by" @click="goToReport(item.item.id)">
                                <span class="employee-img" v-if="item.item.avatar" :style="{ backgroundImage: 'url(' + item.item.avatar + ')' }"></span>
                                <span class="employee-name" :class="{ pending: item.item.dashboard_access == 2 && item.item.status == 1 }">{{ item.item.name }} {{ item.item.surname }}</span>
                            </div>
                        </template>
                        <template #item.level="item">
                            <div class="permissions" @click="goToReport(item.item.id)">
                                <span class="level" v-if="item.item">{{ $t('roles.level') + ' ' + item.item.level }}</span>
                                <span class="description">{{ $t(item.item.description) }}</span>
                            </div>
                        </template>
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToReport(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name" :backColor="'backColor'"></Tags>
                                </template>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && items.length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ items.length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination v-model="pagination.page" @input="top(false)" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && items.length == 0">
                        <!-- <EmptyTable @buttonAction="addRole" :buttonText="$t('user.form.save_new_no_access')" :title="$t('empty_table.users_title')" :description="$t('empty_table.users_desc')"></EmptyTable> -->
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import FilterRoles from '@/components/domain/roles/filter'
import Form from '@/components/domain/roles/form'
import Tags from '@/components/ui/Tags'
import EmptyTable from '@/components/ui/EmptyTable'
import Button from '@/components/ui/Button.vue'

export default {
    components: {
        FilterRoles,
        Form,
        Tags,
        EmptyTable,
        Button
    },
    name: 'Roles',
    data() {
        return {
            noChanges: false,
            roleID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('roles.name'),
                    value: 'name',
                    align: 'start',
                    width: '50%',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('roles.privileges'),
                    align: 'start',
                    sortable: true,
                    value: 'level',
                    width: '50%',
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        hasAccess() {
            return this.checkPermission('/users/add')
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            var items = Object.values(this.$store.getters['employee/getTableRoles']())
            var result = []
            if (items.length > 0) {
                for (var item in items) {
                    var idx = items[item]
                    if (idx.level < 8) {
                        result.push(idx)
                    }
                }
            }
            return result
        },
        itemsLoaded() {
            return this.$store.getters['employee/getItemsRolesLoaded']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        }
    },
    methods: {
        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return {
                            name: self.organizationRender.allLangs[key].name + ' <b>(' + parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) + '%)</b>',
                            backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db'
                        }
                    }
                })
            } else return false
        },

        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        sortColumnLocation(a, b) {
            var Aname = Object.values(a).length > 0 ? Object.values(a)[0].name : ''
            var Bname = Object.values(b).length > 0 ? Object.values(b)[0].name : ''
            if (Aname < Bname) {
                return -1
            }
            return 1
        },
        goToReport(id) {
            if (this.checkPermission('/roles/idRole')) {
                this.form = true
                this.roleID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('user.form.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.roleID = false
            this.$router.push({
                name: 'Roles',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        addRole() {
            if (this.checkPermission('/roles/add')) {
                this.form = true
                this.roleID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('roles.form.no_permission'),
                    duration: 5000
                })
            }
        },
        getRole(id) {
            return this.$store.getters['employee/getFilterRoles'](id)
        },
        load() {
            if (this.$route.params.id) {
                this.goToReport(this.$route.params.id)
            } else if (this.$route.name == 'AddRole') {
                this.addRole()
            } else {
                this.form = false
                this.roleID = false
            }

            var self = this

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '300px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(2, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(2, 0, translationHeader)
                }
            }
        },
        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm') ? moment(value).format('DD/MM/YYYY HH:mm') : '-'
        },
        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('settingsRolesFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('settingsRolesFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route: 'load'
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.user-roles {
    .permissions{
        @include display-flex();
        flex-direction: column;
    }
    .roles-supervise-container {
        height: calc(100% - 100px);
    }
}
</style>
