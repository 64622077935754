<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    components: { FilterActions },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    name: 'FilterRoles',
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                name: ''
            },
            activeFilters: {},

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },
    computed: {
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('employee/setRolesFilters', this.currentFilters)
            this.$store
                .dispatch('employee/loadTableRolesList', {
                    name: definitiveFilters.name
                })
                .then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('employee/setRolesFilters', this.currentFilters)
            this.changesDetected = false
            this.$store.dispatch('employee/loadTableRolesList', { status: 1, name: '' }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['employee/getRolesFilters']
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
// Busca en assets :^)
</style>
