<template>
    <div id="content" class="role-management">
        <div class="bar-actions">
            <TranslateButton :entityId="'employee_rol.' + roleRender.id" :translationLangs="roleRender.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveRole" customClass="btn-action" :bLabel="$route.params.id ? 'user.form.save' : 'roles.add'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="handleDeleteRoleModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="roleRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('user.user_name') }}</h2>
                        <TranslateInput :translation_keyname="'employee_rol.' + roleRender.id + '.name'" @input="updateValues($event)" v-model="roleRender.name" :gOptions="{ mandatory: true }" :_placeholder="$t('roles.name_placeholder')" :gMessage="{ error: $t('user.form.error.name'), error2: $t('roles.error_same_name') }" :gState="errors.name ? 'error' : errors.new_same_name ? 'error2' : ''" gSize="m"></TranslateInput>
                        <!-- <Input @input="updateValues($event)" v-model="roleRender.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('roles.name_placeholder')" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('user.form.error.name'), error2: $t('roles.error_same_name') }" :gState="errors.name ? 'error' : errors.new_same_name ? 'error2' : ''" gSize="m"></Input> -->
                    </div>
                </div>
                <h1 class="form-title noMarginBottom">
                    {{ $t('roles.form.levels') }}
                </h1>
                <div class="label-container roles translate-disable">
                    <p class="description w100">{{ $t('user.form.user_rol_descrip') }}</p>
                    <div class="has-role" :class="{ error: errors.rol_id }">
                        <div class="box-container translate-disable">
                            <div class="no-role" v-for="role in allRoles" :key="role.id" @click="selectNewRole(role)">
                                <div class="box-info">
                                    <div class="select-role" :class="{ selected: role.id == roleRender.rol_id }"></div>
                                    <div class="box-name">{{ $t('roles.level') + ' ' + role.level }}</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.roles.' + role.level) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="errors.rol_id" class="error-form error-field roles" v-html="$t('roles.rol_id_error')"></p>
                    <p v-else class="field-mandatory" v-html="$t('roles.mandatory')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RolesManagement',
    props: { id: undefined },
    components: {},
    data() {
        return {
            refreshInput: 0,
            emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            allRolesList: false,
            role: {
                id: '',
                name: '',
                rol_id: ''
            },
            sendForm: true,
            errors: {
                name: false,
                id: false,
                rol: false,
                rol_changed: false,
                new_same_name: false
            },
            initialRole: false,
            roleID: false,
            moreOptions: false,
            numberEmployeesWithRolAssigned: 0
        }
    },
    computed: {
        roleRender: {
            get() {
                if (this.roleID) {
                    var role = this.$store.getters['employee/getTableRoles'](this.roleID)
                    return role
                }
            },
            set(value) {
                this.$store.commit('employee/createNewRole', value)
            }
        },
        roleLoggedUser() {
            if (this.loggedUser) {
                return this.$store.getters['employee/getRoles'](this.loggedUser.rol)
            }
        },
        optionsRoles() {
            var items = Object.values(this.$store.getters['employee/getDefaultRoles'])
            var result = []
            for (var item in items) {
                var idx = items[item]
                if (idx.level < this.roleLoggedUser.level) {
                    result.push(idx)
                }
            }
            return result
        },
        allRoles: function () {
            return _.orderBy(this.optionsRoles, 'level')
        },
        loggedUser() {
            var userLoggedId = this.$store.getters['login/getLocalEmployee'].employee_id
            var loggedUser = this.$store.getters['employee/getAccountEmployee'](userLoggedId)
            return loggedUser
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.roleRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.roleRender.translations, code)
        },

        updateValues(v) {
            this.roleRender = this.roleRender
        },
        selectNewRole(role) {
            this.roleRender.rol_id = role.id
            this.$forceUpdate()
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        changeRole() {
            this.allRolesList = true
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },

        async load() {
            let self = this
            let roles = this.$store.getters['employee/getTableRoles']()
            let defaultRoles = this.$store.getters['employee/getDefaultRoles']

            if (Object.keys(roles).length == 0) {
                await this.$store.dispatch('employee/loadTableRolesList', {})
            }

            if (Object.keys(defaultRoles).length == 0) {
                await this.$store.dispatch('employee/loadDefaultRolesList', {})
            }

            if (self.$route.name == 'Roles') {
                if (typeof self.id != 'undefined') {
                    self.$router.push({
                        name: 'EditRole',
                        params: { id: self.id }
                    })
                    self.roleID = self.id
                    self.getNumberEmployeesWithRolAssigned()
                } else {
                    self.$store.dispatch('employee/addNewRole').then((id) => {
                        self.roleID = id
                        self.$router.push({ name: 'AddRole' })
                    })
                }
            } else {
                if (typeof self.id != 'undefined') {
                    self.roleID = self.id
                    self.getNumberEmployeesWithRolAssigned()
                } else {
                    self.$store.dispatch('employee/addNewRole').then((id) => {
                        self.roleID = id
                    })
                }
            }
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveRole() {
            var self = this
            self.$overlay.loading()
            var params = { id: self.roleID, validateName: true }
            var name = this.roleRender.name
            this.$store.dispatch('employee/validateRole', params).then((response) => {
                if (response.status) {
                    if (response.errors.rol_changed) {
                        self.$popup.confirm({
                            message: self.$t('roles.changed_role'),
                            textSave: self.$t('roles.change'),
                            textCancel: self.$t('user.popup.delete_text_cancel'),
                            callSave: function () {
                                var sendForm = self.$route.params.id ? 'editRole' : 'addRole'
                                self.$store.dispatch('employee/' + sendForm, self.roleID).then((response) => {
                                    self.goBack()
                                    self.$snackbar.open({
                                        message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + name + '</b>',
                                        customClass: 'success',
                                        duration: 5000
                                    })
                                })
                            },

                            callCancel: function () {
                                self.$overlay.hide()
                            }
                        })
                    } else {
                        var sendForm = self.$route.params.id ? 'editRole' : 'addRole'
                        self.$store.dispatch('employee/' + sendForm, self.roleID).then((response) => {
                            self.goBack()
                            self.$snackbar.open({
                                message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + name + '</b>',
                                customClass: 'success',
                                duration: 5000
                            })
                        })
                    }
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },

        deleteRole() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.roleRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('employee/deleteRole', self.roleRender.id).then(() => {
                self.goBack()
                self.$snackbar.open(snackbarData)
            })
        },

        handleDeleteRoleModal() {
            var self = this

            const popupAlertData = {
                closable: false,
                blocking: false,
                message: self.$tc('roles.assigned_employees', this.numberEmployeesWithRolAssigned == 1 ? 1 : 2, { numbers: this.numberEmployeesWithRolAssigned }),
                callSave: () => {
                    self.$popup.close()
                }
            }

            const popupDeleteData = {
                message: self.$t('roles.delete') + self.roleRender.name + "'</b> ?",
                callSave: this.deleteRole
            }

            if (this.numberEmployeesWithRolAssigned > 0) {
                self.$popup.alert(popupAlertData)
            } else {
                this.$popup.delete(popupDeleteData)
            }
        },

        getNumberEmployeesWithRolAssigned() {
            const employees = this.$store.getters['employee/getAllEmployees']
            for (let employeeId in employees) {
                const employee = employees[employeeId]
                if (employee.rol == this.roleID) {
                    this.numberEmployeesWithRolAssigned++
                }
            }
        }
    },

    created() {
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss">
#content.role-management {
    .form {
        margin-top: -30px;
        .form-title {
            margin-bottom: 8px;
        }
        .label-container {
            padding-bottom: 8px;
        }
    }
    .btn-send {
        height: auto;
    }
    .disabled {
        opacity: 0.55;
        pointer-events: none;
    }

    .inline-delete {
        margin: 60px auto 30px auto;
        width: 100%;
        text-align: center;

        .text {
            @include background($size: 16px, $image: img('delete_error.svg'), $position: left center);
            @include font-size(ml);
            color: $color-error-500;
            text-align: center;
            display: inline-block;
            padding-left: 18px;
            cursor: pointer;
        }
    }

    h1.form-title {
        width: 100%;
        &.noMarginBottom {
            margin-bottom: 8px;
        }
    }
    .description {
        &.w100 {
            width: 100%;
        }
    }
    .has-role {
        @include display-flex();

        button.btn.btn-edit {
            align-self: center;
            width: auto;
            min-width: fit-content;
        }

        &.error {
            border: 1px solid $color-error-500;
        }
    }
    .box-container {
        @include background($color: $color-neutral-200);
        @include display-flex();
        @include flex-wrap(wrap);
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 4px;

        &.hasRole {
            width: unset !important;
        }

        .input-role {
            width: 18px;
        }
        .input-role:checked {
            background-color: red;
        }
        .box-name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
            padding-right: 4px;
        }
        .default-name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
        }
        .have-description {
            @include display-flex();
            @include flex-direction(column);
            width: 100%;
        }
        .no-role {
            @include display-flex();
            @include flex-direction(column);
            width: 50%;
            height: auto;
            padding: 10px;
            cursor: pointer;
        }
        .box-info {
            @include display-flex();

            .select-role {
                cursor: pointer;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 2px solid $color-neutral-600;
                margin-right: 6px;
                &.selected {
                    @include background($image: img('checked_main.svg'));
                    border: none;
                }
            }
        }
        .box-description {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-neutral-600;
            padding-left: 24px;
        }
    }
    .column-description {
        @include display-flex();
        @include flex-direction(column);
        cursor: pointer;
        width: 100%;
    }
    .up {
        @include display-flex();

        .access {
            margin-right: 5px;
            margin-top: 2px;
        }
    }
    .access-container {
        @include background($color: $color-neutral-50);
        @include font-size(xs);
        color: $color-neutral-600;
        padding: 20px 20px;

        .bold-warning {
            @include font-size(xs);
            color: $color-warning-800;
            font-family: $text-bold;
        }
    }
    .sites {
        .v-input {
            @include flex-wrap(wrap);
        }
        .v-menu__content {
            position: inherit;
            width: 100%;
            max-width: unset !important;
        }
    }
}
</style>
